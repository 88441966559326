import { Waypoint } from "react-waypoint";

export default function Skills() {
  const progressBarAnimation = () => {
    let progress = document.querySelectorAll(".progress .progress-bar");
    progress.forEach((el) => {
      el.style.width = el.getAttribute("aria-valuenow") + "%";
    });
  };

  return (
    <section id="skills" className="skills">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <img src="assets/img/skills.png" className="img-fluid" alt="" />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 content"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <h3>Our expertise in Web development</h3>
            <p className="fst-italic">
            Our expertise in Web development emerging new ways to the world of technologies regularly.
             Depending on our client's interests and projects, that might dive deeper into specific areas 
             like mobile web development, progressive web apps, or emerging technologies.
            </p>

            <div className="skills-content">
              <div className="progress">
                <span className="skill">
                  Python <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  {/* progress bar animattion triggers here */}
                  <Waypoint onEnter={progressBarAnimation} />
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  PHP <i className="val">98%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Wordpress <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  React.Js <i className="val">97%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
