import "./css/style.css";

import "aos/dist/aos.css";
import Aos from "aos";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import "boxicons/css/boxicons.min.css";
// import "glightbox/dist/css/glightbox.min.css";
// import 'glightbox/dist/js/glightbox.min.js'
// import 'isotope-layout/dist/isotope.pkgd.js'
// import "remixicon/fonts/remixicon.css";
// import "swiper/swiper-bundle.css";

import { Home } from "./components";

import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import {
  AboutUsPage,
  ContactPage,
  PortfolioPage,
  ServicesPage,
  TeamPage,
} from "./pages";
import Error from "./error";

import { register } from "swiper/element/bundle";
import ProjectDetails from "components/projectDetails";
import Layout from "components/layouts/layout";
import { LayoutForHome } from "components/layouts/layoutForHome";

function App() {
  register();

  useEffect(() => {
    Aos.init();
  });

  return (
    <>
      <Routes>
        <Route path='/' element={<LayoutForHome />}>
          <Route index element={<Home />} />
        </Route>
        <Route path='/' element={<Layout />}>
          <Route path='about' element={<AboutUsPage />} />
          <Route path='services' element={<ServicesPage />} />
          <Route path='portfolio' element={<PortfolioPage />} />
          <Route path='team' element={<TeamPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='project-details/:project' element={<ProjectDetails />} />
          {/* <Route path='project-details'>
            <Route path=':project' element={<ProjectDetails />} />
          </Route> */}
        </Route>
        <Route path='*' element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
