import { Link } from "react-router-dom";

export default function WhyUs() {
    return (
        <section id="why-us" className="why-us section-bg">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content">
                            <h3>HSNA Technologies is the world’s leading <strong>software development company offering solutions</strong></h3>
                            <p>
                            We offer innovative software products and mobile app development. 
                            Our battle-tested team of Web Developers uses rapid development methodologies 
                            & incorporates all the latest frameworks that result in growth and productivity 
                            for an organization.
                            </p>
                        </div>

                        <div className="accordion-list">
                            <ul>
                                <li>
                                    <Link data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span> Our work speaks for itself
                                    <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                                    <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                                        <p>
                                        It’s our job to solve our clients’ problems. We stay curious about your business, 
                                        customers, and design challenges to create a sustainable product you’ll actually use.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <Link data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> We’re the little agency that could <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                                    <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        we are able to give away over millions worth of goods & services every year to deserving small businesses, 
                                        non-profit organizations, and other pillars of the Kansas City community.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <Link data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span>Dream it, build it <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></Link>
                                    <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        Intuitive to use. Simple to maintain. Easy to upgrade. That's what our developers aim for with every experience                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </div>

                    </div>

                    <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{
                        backgroundImage: 'url("assets/img/why-us.png")'
                    }} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                </div>

            </div>
        </section>
    )
}