import { ContactUs, ContactUsHero } from "components";

export default function ContactPage() {
  return (
    <>
      <ContactUsHero />
      <ContactUs />
    </>
  );
}
