import { Link } from "react-router-dom";

export default function Pricing() {
    return (
        <section id="pricing" className="pricing">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Pricing</h2>
                    <p>At HSNA Technologies, we offer tailored web development and software development solutions 
                        to meet your specific needs. Our pricing structure is designed to be flexible and transparent, 
                        ensuring you receive the best value for your investment. Below are some typical pricing options.</p>
                </div>

                <div className="row">

                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="box">
                            <h3>Starter Plan</h3>
                            <h4><sup>$</sup>100<span>per month</span></h4>
                            <ul>
                                <li><i className="bx bx-check"></i> Landing Page</li>
                                <li><i className="bx bx-check"></i> Five Section Page </li>
                                <li><i className="bx bx-check"></i> Static Contents</li>
                                <li><i className="bx bx-check"></i> Ten Revisions</li>
                                <li><i className="bx bx-check"></i>Free support for one month</li>
                                <li className="na"><i className="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
                                <li className="na"><i className="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li>
                            </ul>
                            <Link className="buy-btn">Get Started</Link>
                        </div>
                    </div>

                    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
                        <div className="box featured">
                            <h3>Business Plan</h3>
                            <h4><sup>$</sup>29<span>per month</span></h4>
                            <ul>
                                <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
                                <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
                                <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
                                <li><i className="bx bx-check"></i> Pharetra massa massa ultricies</li>
                                <li><i className="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
                            </ul>
                            <Link className="buy-btn">Get Started</Link>
                        </div>
                    </div>

                    <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                        <div className="box">
                            <h3>Developer Plan</h3>
                            <h4><sup>$</sup>49<span>per month</span></h4>
                            <ul>
                                <li><i className="bx bx-check"></i> Quam adipiscing vitae proin</li>
                                <li><i className="bx bx-check"></i> Nec feugiat nisl pretium</li>
                                <li><i className="bx bx-check"></i> Nulla at volutpat diam uteera</li>
                                <li><i className="bx bx-check"></i> Pharetra massa massa ultricies</li>
                                <li><i className="bx bx-check"></i> Massa ultricies mi quis hendrerit</li>
                            </ul>
                            <Link className="buy-btn">Get Started</Link>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}