import { Link } from "react-router-dom";

export default function Services() {
  return (
    <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Services</h2>
          <p>
          HSNA Technologies is a widely recognized international information technology services provider 
          Software company. Since beginning operations in 2016. HSNA has collaborated with over 100 
          customers and accomplished over 100 projects for organizations across across the globe.
          </p>
        </div>

        <div className="row">
          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bxl-dribbble"></i>
              </div>
              <h4>
                <Link>Website Development</Link>
              </h4>
              <p>
              We Follow all steps requires for different skills and expertise, 
              often involving collaboration between web designers, developers, content creators,
              and digital marketers to create an effective and engaging website.
              </p>
            </div>
          </div>

          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-file"></i>
              </div>
              <h4>
                <Link>Mobile App Development</Link>
              </h4>
              <p>
              We involve designing, coding, testing, 
              and deploying applications tailored for specific operating systems OS such as iOS or Android
              </p>
            </div>
          </div>

          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-tachometer"></i>
              </div>
              <h4>
                <Link>Software Development</Link>
              </h4>
              <p>
              we carry out using various methodologies such as PHP, Python,
              React.Js, WordPRess, Django and depending on project requirements and team preferences
              </p>
            </div>
          </div>

          <div
            className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0"
            data-aos="zoom-in"
            data-aos-delay="400"
          >
            <div className="icon-box">
              <div className="icon">
                <i className="bx bx-layer"></i>
              </div>
              <h4>
                <Link>Complete IT Service</Link>
              </h4>
              <p>
              IT Service typically refers to a comprehensive 
              package of services and support that covers all aspects of information technology
               IT needs for an organization
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
