import { Link } from "react-router-dom";

export default function Team() {
  return (
    <section id="team" className="team section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Team</h2>
          <p>
            Meet the talented individuals driving our team's success with their diverse
            skills and unwavering dedication. Together, we strive to innovate and achieve
            excellence in everything we do.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="pic">
                <img
                  src="assets/img/team/team-1.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
                <p>
                  Explicabo voluptatem mollitia et repellat qui dolorum quasi
                </p>
                <div className="social">
                  <Link>
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link>
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4 mt-lg-0">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="pic">
                <img
                  src="assets/img/team/team-2.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
                <p>
                  Aut maiores voluptates amet et quis praesentium qui senda para
                </p>
                <div className="social">
                  <Link>
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link>
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="pic">
                <img
                  src="assets/img/team/team-3.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>William Anderson</h4>
                <span>CTO</span>
                <p>
                  Quisquam facilis cum velit laborum corrupti fuga rerum quia
                </p>
                <div className="social">
                  <Link>
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link>
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4">
            <div
              className="member d-flex align-items-start"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div className="pic">
                <img
                  src="assets/img/team/team-4.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="member-info">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
                <p>
                  Dolorum tempora officiis odit laborum officiis et et accusamus
                </p>
                <div className="social">
                  <Link>
                    <i className="ri-twitter-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link>
                    <i className="ri-instagram-fill"></i>
                  </Link>
                  <Link>
                    {" "}
                    <i className="ri-linkedin-box-fill"></i>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
