import cardo1 from '../projectImages/cardo/cover.png'
import cardo2 from '../projectImages/cardo/cardo1.png'
import cardo3 from '../projectImages/cardo/cardo2.png'
import cardo4 from '../projectImages/cardo/cardo3.png'

import luminous1 from '../projectImages/luminous/cover.png'
import luminous2 from '../projectImages/luminous/luminous1.png'
import luminous3 from '../projectImages/luminous/luminous2.png'
import luminous4 from '../projectImages/luminous/luminous3.png'

import gathr1 from '../projectImages/gathr/cover.png'
import gathr2 from '../projectImages/gathr/gathr1.png'
import gathr3 from '../projectImages/gathr/gathr2.png'
import gathr4 from '../projectImages/gathr/gathr3.png'

import mimoo1 from '../projectImages/mimoo/cover2.png'
import mimoo2 from '../projectImages/mimoo/mimoo1.png'
import mimoo3 from '../projectImages/mimoo/mimoo2.png'
import mimoo4 from '../projectImages/mimoo/mimoo3.png'

import nexeraRobotics1 from '../projectImages/nexera/cover2.png'
import nexeraRobotics2 from '../projectImages/nexera/nexera1.png'
import nexeraRobotics3 from '../projectImages/nexera/nexera2.png'
import nexeraRobotics4 from '../projectImages/nexera/nexera3.png'

import scoutx1 from '../projectImages/scoutx/cover2.png'
import scoutx2 from '../projectImages/scoutx/scoutx1.png'
import scoutx3 from '../projectImages/scoutx/scoutx2.png'
import scoutx4 from '../projectImages/scoutx/scoutx3.png'

import hassanStore1 from '../projectImages/hassan store/HassanStore.jpeg'
import hassanStore2 from '../projectImages/hassan store/PhoneCategory.jpeg'
import hassanStore3 from '../projectImages/hassan store/PhonePage.jpeg'
import hassanStore4 from '../projectImages/hassan store/Cart.jpeg'

import hsnaTechnologies1 from '../projectImages/hsna technologies/HSNA.jpeg'
import hsnaTechnologies2 from '../projectImages/hsna technologies/HSNA1.jpeg'
import hsnaTechnologies3 from '../projectImages/hsna technologies/HSNA2.jpeg'

import tripify1 from '../projectImages/tripify/Home.jpeg'
import tripify2 from '../projectImages/tripify/Capture.jpeg'
import tripify3 from '../projectImages/tripify/4.jpeg'

export const projects = [
  {
    title: "Cardo",
    description:
      "Cardo is a platform for knowledge sharing applied to the labor market. It brings together consultants from top American companies who, from the app, work as mentors for professionals who would otherwise not have access to such specialized and high-end guidance.",
    projectLink: "hellocardo.org",
    image1Link: cardo1,
    image2Link: cardo2,
    image3Link: cardo3,
    image4Link: cardo4,
  },
  {
    title: "Luminous",
    description:
      "Luminous is a service that lets property owners rent out their spaces to travelers looking for a place to stay. Travelers can rent a space for multiple people to share, a shared space with private rooms, or the entire property for themselves.",
    projectLink: "itsluminous.ca",
    image1Link: luminous1,
    image2Link: luminous2,
    image3Link: luminous3,
    image4Link: luminous4,
  },
  {
    title: "Gathr",
    description:
      "Gathr is a win-win platform for product feedback. It delivers a gift box to each of its subscribers according to their profiles, containing products for them to enjoy and review. Goodies are shipped freely from companies, which receive the client's first-hand feedback in turn.",
    projectLink: "hellogathr.com",
    image1Link: gathr1,
    image2Link: gathr2,
    image3Link: gathr3,
    image4Link: gathr4,
  },
  {
    title: "Mimoo",
    description:
      "In Portuguese, “mimo” means a small, gentle treat, given as an act of care. But Mimoo makes it a two-way road: the platform, specialized in marketing of engagement, offers gifts for consumers in exchange for accurate audience insights for brands. Companies place products they want to test or market research, and customers get free access to products by accomplishing a series of gamified missions on the app and social media. An innovative way for brands to level up in every phase of customer experience.",
    projectLink: "institutional.mimoo.dev",
    image1Link: mimoo1,
    image2Link: mimoo2,
    image3Link: mimoo3,
    image4Link: mimoo4,
  },
  {
    title: "Nexara Robotics",
    description:
      "Combining high-end software and hardware engineering, Nexera brings Neuragrasp: a handy solution for robotic manipulation and grasping, able to reach any type of product, material, and surface. Firmness, grip, and flexibility are a promise from Nexera's pioneering technology.",
    projectLink: "nexera-robotics.com",
    image1Link: nexeraRobotics1,
    image2Link: nexeraRobotics2,
    image3Link: nexeraRobotics3,
    image4Link: nexeraRobotics4,
  },
  {
    title: "ScoutX",
    description:
      "Grounded on a triad of AI, social networks, and gig economy, ScoutX is the ultimate answer to modern sports scouting. Scouts and athletes schedule their meeting matches through the app, and ScoutX-certified professionals review the talents' performance alongside giving them continuous and accurate feedback. By helping athletes reach higher stages of competitions, the company makes the sports market more accessible and fairer, bringing new names to the spotlight and enriching teams globally.",
    projectLink: "scoutxsports.com",
    image1Link: scoutx1,
    image2Link: scoutx2,
    image3Link: scoutx3,
    image4Link: scoutx4,
  },
  {
    title: "Hassan Store",
    description:
      "E-Commerce Shopping Web App, where user can buy new or refurbished mobile phones in Pakistan.",
    projectLink: "hassanstore.com",
    image1Link: hassanStore1,
    image2Link: hassanStore2,
    image3Link: hassanStore3,
    image4Link: hassanStore4,
  },
  {
    title: "HSNA Limited",
    description: "Company Website of HSNATech.",
    projectLink: "hsnatech.com",
    image1Link: hsnaTechnologies1,
    image2Link: hsnaTechnologies2,
    image3Link: hsnaTechnologies3,
  },
  {
    title: "Tripifyy",
    description:
      "It is a platform for tourist and trip organiser. where organiser can select services like hotel, transport, restaurant etc and create a trip and touris can join trip. They can also interact via post and comments if tourist have any queries. tourist will be able to rate after the trip is complete.",
    projectLink: "",
    image1Link: tripify1,
    image2Link: tripify2,
    image3Link: tripify3,
  },
];
