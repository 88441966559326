import { Outlet } from "react-router-dom";
import { Footer, HeaderWithHero } from "./layoutComponents";

export const LayoutForHome = () => {
  return (
    <>
      <HeaderWithHero />
      <Outlet />
      <Footer />
    </>
  );
};
