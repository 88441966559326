import { Link, useLocation } from "react-router-dom";
import { on, select } from "../utilityFunctions";
import Isotope from "isotope-layout";
import Aos from "aos";
import { useEffect } from "react";
import { Waypoint } from "react-waypoint";
import { projects } from "./projectsList";
import Glightbox from "glightbox";

export default function Portfolio() {
  const initializePortfolioAnimations = () => {
    let portfolioContainer = select(".portfolio-container");
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: ".portfolio-item",
      });

      let portfolioFilters = select("#portfolio-flters li", true);

      on(
        "click",
        "#portfolio-flters li",
        function (e) {
          e.preventDefault();
          portfolioFilters.forEach(function (el) {
            el.classList.remove("filter-active");
          });
          this.classList.add("filter-active");

          portfolioIsotope.arrange({
            filter: this.getAttribute("data-filter"),
          });
          portfolioIsotope.on("arrangeComplete", function () {
            Aos.refresh();
          });
        },
        true
      );
    }
  };

  window.addEventListener("load", () => {
    initializePortfolioAnimations();
    Glightbox({
      selector: ".portfolio-lightbox",
    });
  });
  window.addEventListener("locationchange", function () {
    initializePortfolioAnimations();
  });

  const pathname = useLocation().pathname;

  useEffect(() => {
    initializePortfolioAnimations();
  }, [pathname]);

  return (
    <section id="portfolio" className="portfolio">
      <Waypoint onEnter={initializePortfolioAnimations}></Waypoint>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p>
          Welcome to our portfolio, where creativity and innovation converge. 
          We are hooked on a gathering of projects that showcase our passion for strategy and functionality. 
          Each portion reflects our commitment to shaping expressive and impactful IT solutions. 
          Explore and enjoy.
          </p>
        </div>

        <ul
          id="portfolio-flters"
          className="d-flex justify-content-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <li data-filter="*" className="filter-active">
            All
          </li>
          <li data-filter=".filter-app">App</li>
          <li data-filter=".filter-card">Card</li>
          <li data-filter=".filter-web">Web</li>
        </ul>

        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {projects.map((val, key) => {
            return (
              <div
                className="col-lg-4 col-md-6 portfolio-item filter-app"
                key={key}
              >
                <div className="portfolio-img">
                  <img src={val.image1Link} className="img-fluid" alt="" />
                </div>
                <div className="portfolio-info">
                  <h4>{val.title}</h4>
                  <a
                    href={val.image1Link}
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox preview-link"
                    title={val.title}
                  >
                    <i className="bx bx-plus"></i>
                  </a>
                  <Link
                    to={`/project-details/${val.title.toLowerCase().includes(" ") === true
                      ? val.title.toLowerCase().replace(" ", "-")
                      : val.title.toLowerCase()
                      }`}
                    className="details-link"
                    title="More Details"
                  >
                    <i className="bx bx-link"></i>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
