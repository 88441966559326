import Clients from "./clients";
import WhyUs from "./whyUs";
import Skills from "./skills";
import Services from "./services";
import Portfolio from "./portfolio";
import Pricing from "./pricing";

export default function Home() {
  return (
    <>
      <main id='main'>
        {/* <!-- ======= Cliens Section ======= --> */}
        <Clients />
        {/* <!-- End Cliens Section --> */}

        {/* <!-- ======= About Us Section ======= --> */}
        {/* <AboutUs /> */}
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Why Us Section ======= --> */}
        <WhyUs />
        {/* <!-- End Why Us Section --> */}

        {/* <!-- ======= Skills Section ======= --> */}
        <Skills />
        {/* <!-- End Skills Section --> */}

        {/* <!-- ======= Services Section ======= --> */}
        <Services />
        {/* <!-- End Services Section --> */}

        {/* <!-- ======= Cta Section ======= --> */}
        {/* <CallToAction /> */}
        {/* <!-- End Cta Section --> */}

        {/* <!-- ======= Portfolio Section ======= --> */}
        <Portfolio />
        {/* <!-- End Portfolio Section --> */}

        {/* <!-- ======= Team Section ======= --> */}
        {/* <Team /> */}
        {/* <!-- End Team Section --> */}

        {/* <!-- ======= Pricing Section ======= --> */}
        <Pricing />
        {/* <!-- End Pricing Section --> */}

        {/* <!-- ======= Frequently Asked Questions Section ======= --> */}
        {/* <Faq /> */}
        {/* <!-- End Frequently Asked Questions Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
        {/* <ContactUs /> */}
        {/* <!-- End Contact Section --> */}
      </main>
    </>
  );
}
