import { AboutUs, AboutUsHero } from "components";

export default function AboutUsPage() {
  return (
    <>
      <AboutUsHero />
      <AboutUs />
    </>
  );
}
