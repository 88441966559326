import { Link, useNavigate, useParams } from "react-router-dom";
import { projects } from "./projectsList";
import { useEffect } from "react";

export default function ProjectDetails() {
  let projectFound = false;
  let projectToDisplay;

  const { project } = useParams();
  project.toLocaleLowerCase();

  projects.forEach((v) => {
    if (project === v.title.toLowerCase()) {
      projectFound = true;
      projectToDisplay = v;
    }
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!projectFound) {
      navigate("*");
    }
  }, [projectFound, navigate]);

  return (
    <section id='portfolio-details' className='portfolio-details'>
      <div className='container'>
        <div className='row gy-4'>
          {projectToDisplay ? (
            <>
              <div className='col-lg-8'>
                <swiper-container>
                  <swiper-slide>
                    <img
                      className='img-fluid'
                      src={projectToDisplay.image1Link}
                      alt=''
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <img
                      className='img-fluid'
                      src={projectToDisplay.image2Link}
                      alt=''
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <img
                      className='img-fluid'
                      src={projectToDisplay.image3Link}
                      alt=''
                    />
                  </swiper-slide>
                  <swiper-slide>
                    <img
                      className='img-fluid'
                      src={projectToDisplay.image4Link}
                      alt=''
                    />
                  </swiper-slide>
                </swiper-container>
              </div>
              <div className='col-lg-4'>
                <div className='portfolio-info'>
                  <h3>{projectToDisplay.title}</h3>
                  <ul>
                    {/* <li>
                    <strong>Category</strong>: Web design
                  </li>
                  <li>
                    <strong>Client</strong>: ASU Company
                  </li>
                  <li>
                    <strong>Project date</strong>: 01 March, 2020
                  </li> */}
                    <li>
                      <strong>Project URL</strong>:
                      <Link to={projectToDisplay.projectLink}>
                        {" "}
                        {projectToDisplay.projectLink}{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='portfolio-description'>
                  <h2>About {projectToDisplay.title}</h2>
                  <p>{projectToDisplay.description}</p>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
}
