import { Link, useLocation } from "react-router-dom";
import { projects } from "../../projectsList";
import { on, select } from "utilityFunctions";

const navItems = [
  "Home",
  "About",
  "Services",
  "Portfolio",
  "Team",
  "Projects",
  "Contact",
];

let navDropdownItems = [];

projects.forEach((v) => {
  navDropdownItems.push(`${v.title.charAt(0)}${v.title.slice(1)}`);
});

// on('click', '.mobile-nav-toggle', function(e) {
//   select('#navbar').classList.toggle('navbar-mobile')
//   this.classList.toggle('bi-list')
//   this.classList.toggle('bi-x')
// })

const mobileMenu = (e) => {
  const mobileMenuNav = document.querySelector("#navbar");
  mobileMenuNav.classList.toggle("navbar-mobile");
  const mobileMenuIcon = mobileMenuNav.childNodes[1];
  mobileMenuIcon.classList.toggle("bi-list");
  mobileMenuIcon.classList.toggle("bi-x");
};

const mobileMenuDropdown = (e) => {
  if (select("#navbar").classList.contains("navbar-mobile")) {
    e.preventDefault();
    document
      .querySelector("#navbar")
      .childNodes[0].querySelector(".dropdown")
      .childNodes[1].classList.toggle("dropdown-active");
  }
};

export default function Header() {
  const currentPath = useLocation().pathname;
  return (
    <>
      <header id="header" className="sticky-top ">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto">
            <Link to="/">HSNA Technologies</Link>
          </h1>
          {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}

          <nav id="navbar" className="navbar">
            <ul>
              {navItems.map((navItem, key) => {
                return navItem === "Projects" ? (
                  <li
                    className="dropdown"
                    key={key}
                    onClick={mobileMenuDropdown}
                  >
                    <Link>
                      <span>{navItem}</span>
                      <i className="bi bi-chevron-down"></i>
                    </Link>
                    <ul>
                      {navDropdownItems.map((navDropdownItem, key) => {
                        return (
                          <li key={key}>
                            <Link
                              to={`/project-details/${navDropdownItem.toLocaleLowerCase()}`}
                            >
                              {navDropdownItem}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : (
                  <li key={key}>
                    <Link
                      className={`nav-link scrollto ${
                        currentPath ===
                        (navItem === "Home"
                          ? "/"
                          : `/${navItem.toLocaleLowerCase()}`)
                          ? "active"
                          : ""
                      }`}
                      to={
                        navItem === "Home"
                          ? "/"
                          : `/${navItem.toLocaleLowerCase()}`
                      }
                    >
                      {navItem}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <i
              className="bi bi-list mobile-nav-toggle"
              onClick={mobileMenu}
            ></i>
          </nav>
        </div>
      </header>
    </>
  );
}
