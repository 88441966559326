import { Portfolio, PortfolioHero } from "components";

export default function PortfolioPage() {
  return (
    <>
      <PortfolioHero />
      <Portfolio />
    </>
  );
}
