import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <>
            <footer id="footer">

                <div className="footer-newsletter">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <h4>Join Our Newsletter</h4>
                                <p>subscribe to recive our latest updates</p>
                                <form action="" method="post">
                                    <input type="email" name="email"/>
                                    <input type="submit" value="Subscribe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h3>HSNA Technologies</h3>
                                <p>
                                   5th Floor, Panorama Centre # 1<br />
                                   Raja Ghazanfar Ali Khan Road<br />
                                   Karachi, Sindh, Pakistan <br /><br />
                                    <strong>Phone:</strong> +92 300-3386530<br />
                                    <strong>Email:</strong> info@hsnatechnologies.com<br />
                                </p>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/about-us">About us</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/services">Services</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link>Terms of service</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link>Privacy policy</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link>Web Design</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link>Web Development</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link>Product Management</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link>Marketing</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link>Graphic Design</Link></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Social Networks</h4>
                                <p>follow us on social networ pages for upcomming events</p>
                                <div className="social-links mt-3">
                                    <Link className="twitter"><i className="bx bxl-twitter"></i></Link>
                                    <Link className="facebook"><i className="bx bxl-facebook"></i></Link>
                                    <Link className="instagram"><i className="bx bxl-instagram"></i></Link>
                                    <Link className="google-plus"><i className="bx bxl-skype"></i></Link>
                                    <Link className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container footer-bottom clearfix">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Arsha</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
        </>
    )
}