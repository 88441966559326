import { Team, TeamHero } from "components";

export default function TeamPage() {
  return (
    <>
      <TeamHero />
      <Team />
    </>
  );
}
