import { Services, ServicesHero } from "components";

export default function ServicesPage() {
  return (
    <>
      <ServicesHero />
      <Services />
    </>
  );
}
