import Header from "./header";
import Hero from "./hero";

export default function HeaderWithHero() {
  return (
    <>
      <Header />
      <Hero />
    </>
  );
}
