import { Link } from "react-router-dom";

export default function AboutUs() {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>About Us</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <p>
            HSNA Technologies is a leading Pakistan-based IT solutions company
            staffed by a team of skilled professionals in web designing, web development,
            mobile app development, UI/UX design, CRM software, Design and 
            Develop Human Resource Management Information System.
            
            </p>
            <ul>
              <li>
                <i className="ri-check-double-line"></i>We are driven by our mission to revolutionize 
                the delivery of software development projects.

              </li>
              <li>
                <i className="ri-check-double-line"></i> Our battle-tested team of Web Developers 
                uses rapid development methodologies. 
              </li>
              <li>
                <i className="ri-check-double-line"></i> We have a team of experienced product designers and developers
              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>
            We are driven by our mission to revolutionize the delivery of software 
            development projects. We specialize in providing comprehensive software
            solutions and services by expertly handling complex projects to deliver
            outstanding results for our valuable clients.
            </p>
            <Link className="btn-learn-more">Learn More</Link>
          </div>
        </div>
      </div>
    </section>
  );
}
